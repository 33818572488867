.naova_scientific_section {
    padding-bottom: 20px;
}

.naova-scientific-resume .btn-default {
    color: white;
    background-color: #e00b4d;
    border-color: black;
} 

.naova-scientific-resume .btn-default:hover {
    color: #fff;
    background-color: #bf003b;
    border-color: black;
} 