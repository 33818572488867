/* Navigation */
#nav_bar {
    margin-bottom: 0;
    background-color: #ffffff;
    height: auto;
}
.navbar-brand img {
    height: 44px;
    width: auto;
}

.navbar-brand {
    padding: 0;
}

.navbar-nav > li > a {
    font-size: 13px;
    font-weight: 700;
    border-bottom: 2px solid transparent;
    text-transform: uppercase;
    transition: all 0.5s;
    text-decoration: none;
}
.navbar-default .navbar-nav > li > a.active,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    color: #333;
    border-bottom: 4px solid #04007c;
}
.navbar-default .navbar-toggle { border-color: transparent }
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus { background-color: transparent }
.navbar-default .navbar-toggle:hover>.navbar-default .navbar-toggle .icon-bar,
.navbar-default .navbar-toggle:focus>.navbar-default .navbar-toggle .icon-bar { color: #04007c }

.navbar_small_bar {
    position: absolute;
    padding-top: 14px;
}

.navbar.navbar-fixed-top .navbar-collapse.not_collapse{
    text-align: left;
}

.nav>li>a.navbar-item-naova{
    padding: 15px 10px;
}

.navbar-item-naova {
    cursor: pointer;
    color:black;
    text-decoration: none;
}

.button-live{
    color:white;
    background-color: red;
    margin: 10px;
}

@media (min-width:992px){
    .navbar-brand{
        margin: 0 -30px;
    }
}