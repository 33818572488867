.naova_press_section {
    padding-bottom: 20px;
}

@supports (text-align-last: center) {
    .naova-press-resume{
        text-align: justify;
        -moz-text-align-last: center;
        text-align-last: center;
    }
}

.naova-press-resume{
    text-align: center;
}

.naova-press-resume .btn-default {
    color: white;
    background-color: #e00b4d;
    border-color: black;
} 

.naova-press-resume .btn-default:hover {
    color: #fff;
    background-color: #bf003b;
    border-color: black;
} 

.press-card {
    background: rgb(100,100,100);
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding: 1rem;
    margin: 1rem;
    margin-bottom: 0rem;
    flex: 1;
    height: 24rem;
    border: 2px solid black;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.press-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: white;
    text-align: start;
    margin-bottom: 0;
    width: 100%;
    z-index: 1;
}

.press-card-date {
    font-size: 1rem;
    font-weight: 400;
    color: white;
    text-align: center;
    margin-bottom: 1rem;
    z-index: 1;
    font-style: italic;
}

.press-card-summary {
    font-size: 1rem;
    font-weight: 400;
    color: white;
    text-align: justify;
    text-overflow: ellipsis;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;
    z-index: 1;
}

.press-card-summary::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #222222;
    border-radius: 4rem;
}

.press-card-summary::-webkit-scrollbar
{
	width: 6px;
	background-color: #222222;
    border-radius: 4rem;
}

.press-card-summary::-webkit-scrollbar-thumb
{
	background-color: #f5f5f5;
    border-radius: 4rem;
}

.press-section-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 0px;
    z-index: 1;
}

@media (max-width: 992px) {

    .press-section-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 16px;
        grid-row-gap: 0px;
        z-index: 1;
    }

}

.press-card-source {
    margin-top: 1rem;
    margin-bottom: 0;
    font-size: 14px;
    z-index: 1;
    color: white;
}

.press-card-img {
    height: 100%;
    min-width: 100%;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: blur(5px) brightness(0.5);
    position: absolute;
}