#tf-sponsors {
    background: url(../../img/partenaire/team_partenaire.jpg);
    background-size: cover;
    background-position: top;
    background-attachment: fixed;
    background-repeat: no-repeat;
    color: #cfcfcf;
}
#tf-sponsors .overlay {
    background: -moz-linear-gradient(top,  rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.73) 17%, rgba(0,0,0,0.66) 35%, rgba(0,0,0,0.55) 62%, rgba(0,0,0,0.4) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.8)), color-stop(17%,rgba(0,0,0,0.73)), color-stop(35%,rgba(0,0,0,0.66)), color-stop(62%,rgba(0,0,0,0.55)), color-stop(100%,rgba(0,0,0,0.4))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* W3C */
    padding: 5% 0;
    background-attachment: fixed;
}
#tf-sponsors h1 {
  color: #ffffff;
  /* text-shadow: 2px 0 0 #04007c, -2px 0 0 #04007c, 0 2px 0 #04007c, 0 -2px 0 #04007c, 1px 1px #04007c, -1px -1px 0 #04007c, 1px -1px 0 #04007c, -1px 1px 0 #04007c; */
}

center {
    margin: 5px;
}

.btn.btn_become_sponsor{
    font-size: 22px;
    color: #fff;
    background-color: #e00b4d;
    border-color: black;
} 

.btn.btn_become_sponsor:hover{
    color: #fff;
    background-color: #bf003b;
    border-color: black;
} 