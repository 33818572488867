/* Home Style */
#tf-home {
    background: url(../../img/bg/nao.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    color: #cfcfcf;
}
#tf-home .overlay {
    background: -moz-linear-gradient(top,  rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.73) 17%, rgba(0,0,0,0.66) 35%, rgba(0,0,0,0.55) 62%, rgba(0,0,0,0.4) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.8)), color-stop(17%,rgba(0,0,0,0.73)), color-stop(35%,rgba(0,0,0,0.66)), color-stop(62%,rgba(0,0,0,0.55)), color-stop(100%,rgba(0,0,0,0.4))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.73) 17%,rgba(0,0,0,0.66) 35%,rgba(0,0,0,0.55) 62%,rgba(0,0,0,0.4) 100%); /* W3C */
    padding: 1% 0;
    background-attachment: fixed;
}
#tf-home h1 {
	color: #ffffff;
	/* text-shadow: 2px 0 0 #04007c, -2px 0 0 #04007c, 0 2px 0 #04007c, 0 -2px 0 #04007c, 1px 1px #04007c, -1px -1px 0 #04007c, 1px -1px 0 #04007c, -1px 1px 0 #04007c; */
}

a.goto-btn {
    font-size: 18px;
    font-weight: 700;
    border-bottom: 2px solid transparent;
    padding: 10px 0;
    transition: all 0.5s;
}
a.goto-btn:hover {
    color: #ffffff;
    border-bottom: 2px solid #04007c;
}
a.goto-arrow {
    font-size: 18px;
    font-weight: 700;
    border-bottom: 2px solid transparent;
    padding: 10px 0;
    transition: all 0.5s;
    color: #cfcfcf;
}
a.goto-arrow:hover {
    color: #ffffff;
	transform: translateY(20px);
}

.partenaire_ubiMtl_img { 
    background: rgba(255, 255, 255, 0.50);
    padding: 5px;
}

.partenaire_equisoft_img {
    background: rgba(255, 255, 255, 0.50);
}

.img-responsive {
    max-width: 100%;
    height: auto;
}

.intro-logo {
    width: 30rem;
}

.partenaire_ets_img {
    width: auto;
}

.partenaire_img {
    width: 100%;
}

.partenaire-wrapper {
    display:flex;
    flex-direction: row;
    justify-content: center;
    
}

.partenaire_teledyne_img {
    max-width: 100%;
}

.partenaire_rousseau_img {
    padding: 2rem 0;
    max-width: 80%;
}

@media (max-width: 992px) {
    .partenaire_ets_img {
        height: auto;
        width: 80%;
    }

    .partenaire_aeets_img {
        height: auto;
        width: 80%;
    }

    .partenaire_ubiMtl_img {
        width: 70%;
    }

    .partenaire_equisoft_img {
        width: 70%;
    }
}
