.team-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    background-color: rgba(255, 255, 255, 0.90);
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.fancy {
    line-height: 0.5;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 20px;
}

.team-img {
    margin: 10px;
    max-height: 100%;
}

.team-member-img {
    display: flex;
}

.team-title {
    position: static;
    display: inline-block;
    letter-spacing: 2px;
    width: 100%;
    height: 80px;
}

.team-title h5 {
    margin-bottom: 0px;
    display: block;
    text-transform: uppercase;
}

.team-title span {
    font-size: 12px;
    text-transform: uppercase;
    color: #a5a5a5;
    letter-spacing: 1px;
}

.card-team-member {
    padding: 10px;
}

.team-title .team-student span {
    font-size: 8px;
}

.team-member {
    height: auto;
}

.img-membre-profil {
    border-radius: 50%;
}