#map {
    position: relative;
    width: 100%;
    height: 400px;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.contact-detail {
    text-align: center;
    margin: 60px 0;
}

.contact-detail i.fa {
    text-align: center;
    font-size: 30px;
    background-color: #e00b4d;
    color: #ffffff;
    padding: 25px 0;
    border-radius: 50px;
    border-top-left-radius: 0;
    margin: 0 10px;
    margin-right: 20px;
    width: 82px;
    height: 80px;
    transition: background-color 0.5s;
}

.contact-detail:hover i.fa { background-color: #bf003b }

.contact-detail a {
    color: inherit;
}