.service .media-right i.fa {
    text-align: center;
    font-size: 30px;
    background-color: #e00b4d;;
    color: #ffffff;
    padding: 25px 0;
    border-radius: 50px;
    border-top-left-radius: 0;
    margin: 0 10px;
    margin-right: 20px;
    width: 82px;
    height: 80px;
    transition: background-color 0.5s;
}

.service .media-right i.fa:hover {
    background-color: #bf003b;;
}

.service .media-left i.fa {
    text-align: center;
    font-size: 30px;
    background-color: #e00b4d;;
    color: #ffffff;
    padding: 25px 0;
    border-radius: 50px;
    border-top-right-radius: 0;
    margin: 0 10px;
    margin-left: 20px;
    width: 82px;
    height: 80px;
    transition: background-color 0.5s;
}

.service .media-left i.fa:hover {
    background-color: #bf003b;;
}

.media-heading {
    color: #e00b4d;
}

.media-heading a{
    color: #e00b4d;
}

.media-heading:hover {
    text-decoration: none;
    color: #bf003b;
}

.media-heading a:hover {
    text-decoration: none;
    color: #bf003b;
}

.summary_section {
    padding-bottom: 40px;
}

.naova_summary_unit {
    text-align: justify;
}